.container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* horizontal */
    align-items: center;     /* vertical */
    width: 100%;
}

.result {
    margin-top: 100px;
    word-wrap: break-word;
    width: 300px;
}