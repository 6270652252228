.loader {
    position: relative;
    height: 50px;
    width: 80%;
    font-family: Arial, Helvetica, sans-serif;
}

.dot {
    position: absolute;
    height: 50px;
    width: 50px;
    background: var(--c);
    animation: loader 3s ease-in-out var(--d) infinite;
    border-radius: 100%;
    /* border: 2px solid white; */
    z-index: calc(var(index) + 1);
}

.text {
    position: absolute;
    content: "loading";
    width: 4rem;
    font-weight: bold;
    top: 150%;
    left: 0;
    right: 0;
    margin: auto;
    animation: loading-text 3s infinite;
}

@keyframes loader {
    0% {
        left: 0;
    }
    15% {
        left: calc(var(--index) * 5px);
    }
    45% {
        left: calc(var(--index) * 5px + 100%);
    }
    60% {
        left: calc(var(--index) * 5px + 100%);
    }
    95% {
        left: calc(var(--index) * 5px);
    }
    100% {
        left: 0;
    }
}

@keyframes loading-text {
    0% {
        content: "loading.";
    }

    25% {
        content: "loading..";
    }
    50% {
        content: "loading..";
    }
    75% {
        content: "loading....";
    }
}
