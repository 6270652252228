@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");

* {
    box-sizing: border-box;
}

html::-webkit-scrollbar {
    display: none;
}

body {
    font-family: "Montserrat", sans-serif;
    background-color: #f2f4f8;
    margin: 0;
    box-sizing: border-box;
    overscroll-behavior-y: contain;
}
/* #root {
    background-color: #f2f4f8;
} */
a {
    text-decoration: none;
    display: block;
    color: #000000;
}

button {
    outline: none;
    border: none;
}

.input,
.passwordInput,
.emailInput,
.nameInput,
.textarea {
    box-shadow: rgba(0, 0, 0, 0.11);
    border: none;
    background: #ffffff;
    border-radius: 3rem;
    height: 3rem;
    width: 100%;
    outline: none;
    font-family: "Montserrat", sans-serif;
    padding: 0 3rem;
    font-size: 1rem;
}

@media (min-width: 1100px) {

    .input,
    .passwordInput,
    .emailInput,
    .nameInput,
    .textarea {
        padding: 0 5rem;
    }
}

.textarea {
    padding: 1rem 1.5rem;
    height: 300px;
    border-radius: 1rem;
}

.primaryButton {
    /* min-width: 200px; */
    min-width: 250px;
    cursor: pointer;
    background: #00cc66;
    border-radius: 1rem;
    padding: 0.85rem 2rem; 
    color: #ffffff;
    font-weight: 600;
    font-size: 1.25rem;
    width: 100%;
    margin-top: 16px;
    margin: 16 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.primaryButton3 {
    /* min-width: 200px; */
    min-width: 250px;
    cursor: pointer;
    background: #ffffff;
    border-radius: 1rem;
    padding: 0.85rem 2rem; 
    color: #00cc66;
    font-weight: 600;
    font-size: 1.25rem;
    width: 100%;
    margin-top: 16px;
    margin: 16 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-width: 1px;
}

.secondaryButton2 {
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 1rem;
    margin-top: 16px;
    padding: 0.85rem 2rem;
    color: #00cc66;
    font-weight: 600;
    font-size: 1.25rem;
    width: 100%;
    margin: 5 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: #00cc66;
    border-style: solid;
    min-width: 300px;
}

.secondaryButton {
    min-width: 300px;
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 0.85rem 2rem;
    color: #000000;
    font-weight: 600;
    font-size: 1.25rem;
    width: '80%';
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.01rem;
    border-color: #00cc66;
    border-style: solid;
}

.removeIcon {
    cursor: pointer;
    position: absolute;
    top: 45%;
    right: -2%;
    margin-right: 1.5rem;
}

.editIcon {
    cursor: pointer;
    position: absolute;
    top: -3.4%;
    right: -2%;
    margin-right: 1.5rem;
}

.pageContainer,
.offers,
.profile,
.category,
.explore {
    padding-bottom: 75px;
}

@media (min-width: 1024px) {

    .pageContainer,
    .offers,
    .profile,
    .category,
    .explore {
        padding-bottom: 75px;
    }
}


.listingDetails {
    margin: 1rem;
    margin-top: 3rem;
}

@media (min-width: 1024px) {
    .listingDetails {
        margin: 3rem;
    }
}


.loginLayout{
    max-width: 400px;
    margin: auto;   
}

.loadingSpinnerContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingSpinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #00cc66 transparent #00cc66 transparent;
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.pageHeader {
    font-size: 1.5rem;
    font-weight: 400;
}

.navbar {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    height: 85px;
    background-color: #ffffff;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarNav {
    width: 100%;
    margin-top: 0.75rem;
    overflow-y: hidden;
}

.navbarListItems {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.navbarListItem {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navbarListItemName,
.navbarListItemNameActive {
    margin-top: 0.25rem;
    font-size: 14px;
    font-weight: 600;
    color: #8f8f8f;
}

.navbarListItemNameActive {
    color: #2c2c2c;
}

.nameInput {
    margin-bottom: 2rem;
    background: url("./assets/svg/badgeIcon.svg") #ffffff 2.5% center no-repeat;
}

.emailInput {
    margin-bottom: 2rem;
    background: url("./assets/svg/personIcon.svg") #ffffff 2.5% center no-repeat;
}

.passwordInputDiv {
    position: relative;
}

.passwordInput {
    margin-bottom: 2rem;
    background: url("./assets/svg/lockIcon.svg") #ffffff 2.5% center no-repeat;
}

.showPassword {
    cursor: pointer;
    position: absolute;
    top: -4%;
    right: 1%;
    padding: 1rem;
}

.forgotPasswordLink {
    cursor: pointer;
    color: #00cc66;
    font-weight: 600;
    text-align: right;
}

.signInBar,
.signUpBar {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: inherit;
}

.signInButton,
.signUpButton,
.signInText,
.nextButton,
.signUpText {
    cursor: pointer;
}

@media (min-width: 1024px) {

    .signInBar,
    .signUpBar {
        justify-content: start;
    }
}

.signInText,
.signUpText {
    font-size: 1.5rem;
    font-weight: 700;
}

.signInButton,
.signUpButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    background-color: #00cc66;
    border-radius: 50%;
}

@media (min-width: 1024px) {

    .signInButton,
    .signUpButton {
        margin-left: 3rem;
    }
}

.socialLogin {
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.socialIconDiv {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
    margin: 1.5rem;
    width: 3rem;
    height: 3rem;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.socialIconImg {
    width: 100%;
}

.socialIconImg2 {
    margin-right: 15px;
    width: 30px;
    height: 30px;
}

.registerLink {
    margin-top: 1rem;
    color: #00cc66;
    font-weight: 600;
    text-align: center;
    margin-bottom: 3rem;
}

.registerLink2 {
    color: #00cc66;
}

@media (min-width: 1217px) {
    .explore {
        margin-bottom: 10rem;
    }
}

@media (max-height: 536) {
    .explore {
        margin-bottom: 10rem;
    }
}

.exploreHeading,
.exploreCategoryHeading {
    font-weight: 700;
}

.exploreCategoryHeading {
    /* margin-top: 3rem; */
}

.swiper-container {
    min-height: 225px;
    height: 23vw;
}

.swiper-pagination-bullet-active {
    background-color: #ffffff !important;
}

.swiperSlideDiv {
    position: relative;
    width: 100%;
    height: 100%;
}

.swiperSlideImg {
    width: 100%;
    object-fit: cover;
}

.swiperSlideText {
    color: #ffffff;
    position: absolute;
    bottom: 35px;
    left: 10px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0.5rem;
}

@media (min-width: 1024px) {
    .swiperSlideText {
        font-size: 1.75rem;
    }
}

.swiperSlidePrice {
    color: #000000;
    position: absolute;
    top: 143px;
    left: 11px;
    font-weight: 600;
    max-width: 90%;
    background-color: #ffffff;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
}

@media (min-width: 1024px) {
    .swiperSlidePrice {
        font-size: 1.25rem;
    }
}

.exploreCategories {
    float: none;
    clear: both;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    margin: auto;
    text-align: center;
    align-content: center;
}

.exploreCategories2 {
    width: 50%;
    max-width: 200px;
}

.exploreCategories3 {
    margin-left: 15px; 
    margin-right: -15px; 
    float: none;
    clear: both;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    /* margin: auto; */
    text-align: center;
    align-content: center;
}

.exploreCategories a {
    width: 48%;
}

.exploreCategoryImg {
    min-height: 115px;
    height: 15vw;
    width: 100%;
    border-radius: 1.5rem;
    object-fit: cover;
    margin: 0 auto;
}

.exploreCategoryName {
    font-weight: 500;
    text-align: left;
}

.category {
    margin-bottom: 10rem;
}

.categoryListings {
    padding: 0;
}

.categoryListing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
}

.categoryListingStarted {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    border-radius: 1.5rem;
    border-color: darkred;
    border-style: solid;
}

.categoryListingLink {
    display: contents;
}

.categoryListingImg {
    width: 30%;
    height: 100px;
    /* border-radius: 1.5rem; */
    object-fit: cover;
}

@media (min-width: 1024px) {
    .categoryListingImg {
        width: 19%;
        height: 217px;
    }
}

.categoryListingImgStarted {
    width: 30%;
    height: 100px;
    border-radius: 1.5rem;
    object-fit: cover;
    border-color: darkred;
    border-style: solid;
}

@media (min-width: 1024px) {
    .categoryListingImgStarted {
        width: 19%;
        height: 217px;
    }
}

.categoryListingDetails {
    width: 65%;
}

@media (min-width: 1024px) {
    .categoryListingDetails {
        width: 79%;
    }
}

.categoryListingLocation {
    /* font-weight: 600; */
    font-size: 0,741656366rem;
    /* opacity: 0.8; */
    margin-bottom: 0;
}

.categoryListingMember {
    /* font-weight: 600; */
    font-size: 0.7rem;
    opacity: 0.5;
    margin-bottom: 0;
    padding-top: 10px;
}

.categoryListingName {
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0;
}

.categoryListingPrice {
    margin-top: 0.5rem;
    font-weight: 600;
    font-size: 1.1rem;
    color: #00cc66;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.categoryListingInfoDiv {
    display: flex;
    justify-content: space-between;
    max-width: 275px;
}

.categoryListingInfoText {
    font-weight: 500;
    font-size: 0.7rem;
}

.loadMore {
    cursor: pointer;
    width: 8rem;
    margin: 0 auto;
    text-align: center;
    padding: 0.25rem 0.5rem;
    background-color: #000000;
    color: #ffffff;
    font-weight: 600;
    border-radius: 1rem;
    opacity: 0.7;
    margin-top: 2rem;
}

.listingDetails {
    margin-bottom: 10rem;
}

.shareIconDiv {
    cursor: pointer;
    position: fixed;
    top: 12px;
    right: 5%;
    z-index: 2;
    background-color:rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backIconDiv {
    cursor: pointer;
    position: fixed;
    top: 3%;
    right: 5%;
    z-index: 2;
    background-color: #ffffff;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.listingName {
    font-weight: 600;
    font-size: 1.618rem;
    margin-bottom: 0.5rem;
}


.listingLocation {
    margin-top: 0;
    font-weight: 600;
}

.discountPrice {
    padding: 0.25rem 0.5rem;
    background-color: #000000;
    color: #ffffff;
    border-radius: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    display: inline;
}

.listingType {
    padding: 0.25rem 0.5rem;
    background-color: #00cc66;
    color: #ffffff;
    border-radius: 2rem;
    display: inline;
    font-weight: 600;
    font-size: 0.8rem;
    /* margin-right: 1rem; */
}

.listingDetailsList {
    padding: 0;
    list-style-type: none;
}

.listingDetailsList li {
    margin: 0.3rem 0;
    font-weight: 500;
    opacity: 0.8;
}

.listingLocationTitle {
    margin-top: 2rem;
    font-weight: 600;
    font-size: 1.25rem;
}

.leafletContainer {
    width: 100%;
    height: 200px;
    overflow-x: hidden;
    margin-bottom: 3rem;
}

@media (min-width: 1024px) {
    .leafletContainer {
        height: 400px;
    }
}

.linkCopied {
    position: fixed;
    top: 9%;
    right: 5%;
    z-index: 2;
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    font-weight: 600;
}

.contactListingName {
    margin-top: -1rem;
    margin-bottom: 0;
    font-weight: 600;
}

.contactListingLocation {
    margin-top: 0.25rem;
    font-weight: 600;
}

.contactLandlord {
    margin-top: 2rem;
    display: flex;
    align-items: center;
}

.landlordName {
    font-weight: 600;
    font-size: 1.2rem;
}

.messageForm {
    margin-top: 0.5rem;
}

.messageDiv {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
}

.messageLabel {
    margin-bottom: 0.5rem;
}

.profileList {
    /* margin-bottom: 10rem;
    padding-bottom: 3rem; */
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 75px;
    margin: auto;
    /* text-align: center; */
    align-content: center;
    max-width: 600px;
}

.profile {
    /* margin-bottom: 10rem;
    padding-bottom: 3rem; */
    padding-bottom: 75px;
    max-width: 600px;
    align-content: center;
    margin: auto;
}


.profileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logOut {
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
    background-color: #00cc66;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
}

.profileDetailsHeader {
    display: flex;
    justify-content: space-between;
    /* max-width: 500px; */
}

.personalDetailsText {
    font-weight: 600;
}

.changePersonalDetails {
    cursor: pointer;
    font-weight: 600;
    color: #00cc66;
}

.profileCard {
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.2);
    /* max-width: 500px; */
}

.profileDetails {
    display: flex;
    flex-direction: column;
}

.profileName,
.profileEmail,
.profileAddress,
.profileAddressActive,
.profileEmailActive,
.profileNameActive {
    all: unset;
    margin: 0.3rem 0;
    font-weight: 600;
    width: 100%;
}

.profileNameActive {
    background-color: rgba(44, 44, 44, 0.1);
}

.profileEmail,
.profileAddress,
.profileAddressActive,
.profileEmailActive {
    font-weight: 500;
}

.profileEmailActive {
    background-color: rgba(44, 44, 44, 0.1);
}

.profileAddressActive {
    background-color: rgba(44, 44, 44, 0.1);
}

.createListing {
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 0.25rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.2);
    margin-top: 2rem;
    font-weight: 600;
    /* max-width: 500px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.listingText {
    margin-top: 3rem;
    font-weight: 600;
}

.lisitingsList {
    padding: 0;
}

.formLabel {
    font-weight: 600;
    margin-top: 1rem;
    display: block;
    text-align: center;
}

.formButtons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.formButton,
.formInput,
.formInputAddress,
.formInputName,
.formInputSmall,
.formInputFile,
.formButtonActive {
    padding: 0.9rem 3rem;
    background-color: #ffffff;
    font-weight: 600;
    border-radius: 1rem;
    font-size: 1rem;
    margin: 0.5rem 0.5rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formButtonActive {
    background-color: #00cc66;
    color: #ffffff;
}

.flex {
    display: flex;
}

.formInput,
.formInputAddress,
.formInputName,
.formInputSmall,
.formInputFile {
    border: none;
    outline: none;
    font-family: "Montserrat", sans-serif;
}

.formInputSmall,
.formInputFile {
    margin-right: 3rem;
    padding: 0.9rem 0.7rem;
    text-align: center;
}

.formInputName {
    padding: 0.9rem 1rem;
    width: 100%;
    /* max-width: 326px; */
}

.formInputAddress {
    padding: 0.9rem 1rem;
    width: 100%;
    /* max-width: 326px; */
}

.formPriceDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.formPriceText {
    margin-left: -1.5rem;
    font-weight: 600;
}

.imagesInfo {
    font-size: 0.9rem;
    opacity: 0.75;
    text-align: center;
}

.formInputFile {
    width: 100%;
}

.formInputFile::-webkit-file-upload-button {
    background-color: #00cc66;
    border: none;
    color: #ffffff;
    font-weight: 600;
    padding: 0.5rem 0.75rem;
    border-radius: 1rem;
    margin-right: 1rem;
}

.createListingButton {
    margin-top: 5rem;
}

.offers {
    margin-bottom: 10rem;
}

.offerBadge {
    padding: 0.25rem 0.5rem;
    background-color: #000000;
    color: #ffffff;
    border-radius: 1rem;
    margin-left: 1rem;
    font-size: 0.8rem;
    opacity: 0.75;
}


/* Slider Custom CSS */

.swiper-button-prev,
.swiper-button-next {
    color: #fff;
}


/* Custom Delete */

@media only screen and (max-width: 600px) {
    .removeIcon {
        margin-right: 0;
    }

    .editIcon {
        margin-right: 0;
    }
}

@media only screen and (max-width: 768px) {
    .swiper {
        /* background-color: red !important; */
        /* max-height: 300px; */
        /* max-height: 32vh; */
        /* max-height: fit-content; */
    }
}

.listingsList {
    /* margin-inline-start: -40px; */
    max-width: 500px;
}

.text-center {
    text-align: center;
}

.formRooms {
    align-items: center;
    justify-content: center;
}

.catchyTitle {
    font-size: 0.9rem;
    opacity: 0.75;
    text-align: center;
}

.logoHeader {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
}

.groupHeaderDiv {
    position: relative;
    width: 100%;
    height: 100%;
}

.groupHeaderDiv2 {
    position: relative;
    width: 100%;
}

.groupHeaderImg {
    width: 100%;
    object-fit: cover;
    width: 100%;
}

.groupHeaderText {
    color: #ffffff;
    position: absolute;
    bottom: -10px;
    left: 10px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.2); */
    padding: 0.5rem;
}

.groupHeaderText3 {
    color: black;
    position: absolute;
    bottom: 140px;
    left: 10px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.2); */
    padding: 0.5rem;
}

.groupHeaderText2 {
    color: #ffffff;
    position: absolute;
    bottom: -10px;
    left: 10px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.2); */
    padding: 0.5rem;
}

.groupCoverAvatar {
    color: #ffffff;
    position: absolute;
    bottom: -50px;
    left: 10px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.2); */
    padding: 0.5rem;
}

.groupCoverName {
    color: #000;
    position: absolute;
    bottom: -60px;
    left: 130px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.2); */
    padding: 0.5rem;
    vertical-align: top;
    height: 80px;
}

.userCoverName {
    color: #000;
    position: absolute;
    bottom: -75px;
    left: 130px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1.2rem;
    /* background-color: rgba(0, 0, 0, 0.2); */
    padding: 0.5rem;
    vertical-align: top;
    height: 80px;
}
.userCoverEmail {
    color: #000;
    position: absolute;
    bottom: -95px;
    left: 130px;
    font-weight: 600;
    max-width: 90%;
    font-size: 0.741rem;
    /* background-color: rgba(0, 0, 0, 0.2); */
    padding: 0.5rem;
    vertical-align: top;
    height: 80px;
}

.questionnaireCoverName {
    color: #000;
    position: absolute;
    bottom: -80px;
    left: 130px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.2); */
    /* padding: 0.5rem; */
    vertical-align: top;
    height: 80px;
}

.questionnaireScopeName {
    color: #ffffff;
    position: absolute;
    bottom: 5px;
    right: 125px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.8); */
    padding: 0.5rem;
}

.questionnaireRolesName {
    color: #000000;
    position: absolute;
    bottom: 10px;
    right: 5px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.1); */
    padding: 0.1rem;
}

.groupCoverMembers {
    color: #ffffff;
    position: absolute;
    bottom: 0px;
    right: 25px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.8); */
    padding: 0.5rem;
}

@media (min-width: 1024px) {
    .groupHeaderText {
        font-size: 1.75rem;
    }
}

.groupHeaderTextRight {
    color: #ffffff;
    position: absolute;
    bottom: -10px;
    right: 25px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.8); */
    padding: 0.5rem;
}

.groupHeaderTextRight3 {
    color: #ffffff;
    position: absolute;
    bottom: -25px;
    right: 25px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.8); */
    padding: 0.5rem;
}

@media (min-width: 1024px) {
    .groupHeaderTextRight {
        font-size: 1.75rem;
    }
}

.groupHeaderTextIcon {
    color: #ffffff;
    position: absolute;
    bottom: -50px;
    right: 10px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.1); */
    padding: 0.5rem;
}

.groupHeaderTextIcon2 {
    color: #000000;
    position: absolute;
    bottom: -50px;
    right: 10px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.1); */
    padding: 0.5rem;
}

.groupHeaderTextIcon3 {
    color: #ffffff;
    position: absolute;
    bottom: -90px;
    right: 0px;
    font-weight: 600;
    max-width: 90%;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.1); */
    padding: 0.5rem;
}

.groupHeaderCloseIcon {
    position: absolute;
    top: 50 px;
    right: 10px;
}

.groupHeaderTitle {
    position: absolute;
    top: -50px;
    /* left: 10px; */
    color: #f2f4f8;
    /* background: rgba(255, 255, 255, 0.8); */
    border-radius: 10px;
    padding: 2px;
    width: 100%;
    text-align: center;
    text-shadow: 1px 1px #000;
}

@media (min-width: 1024px) {
    .groupHeaderTextIcon {
        font-size: 1.75rem;
    }
}

.groupHeaderPrice {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 143px;
    /* color: #000000;
    position: absolute;
    top: 143px;
    font-weight: 600;
    max-width: 90%;

    margin: auto;
    padding: 10px;
    border-radius: 1rem; */
}

.groupHeaderPrice3 {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 43px;
    /* color: #000000;
    position: absolute;
    top: 143px;
    font-weight: 600;
    max-width: 90%;

    margin: auto;
    padding: 10px;
    border-radius: 1rem; */
}

.groupHeaderPriceMini {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 43px;
    /* color: #000000;
    position: absolute;
    top: 143px;
    font-weight: 600;
    max-width: 90%;

    margin: auto;
    padding: 10px;
    border-radius: 1rem; */
}

.groupHeaderPrice2 {
    color: #ffffff;
    position: absolute;
    top: -90px;
    background: rgba(0, 0, 0, 0.5);
    font-weight: 800;
    max-width: 90%;
    margin: auto;
    padding: 10px;
    border-radius: 1rem;

}

@media (min-width: 1024px) {

    .groupHeaderPrice,
    .groupHeaderPriceMini,
    .groupHeaderPrice2 {
        font-size: 1.25rem;
    }
}

.next {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: inherit;
    margin-right: 1rem;
    margin-left: 1rem;

}

@media (min-width: 1024px) {

    .next,
    .next {
        justify-content: start;
    }
}

.nextText {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 1rem;
}

.nextButton {
    padding: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    /* background-color: #1f00cc; */
    border-radius: 50%;
}

.next1 {
    top: 16px;
    left: 0px;
    width: 100%;
    /* min-height: 500px; */
    height: auto;

    position: relative;
    padding: 40px;
    justify-content: center;
}

.next2 {
    margin: 60px;
    height: auto;
    margin: auto;
    padding: 0px;
    position: relative;
    background-color: red;
}

.next3 {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: inherit;
    /* margin-right: 1rem;
    margin-left: 1rem; */

    min-width: 250px;
    bottom: 0em;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
}

@media (min-width: 1024px) {
    .Button {
        margin-left: 3rem;
    }
}

.qpage {
    margin-left: 10;
    background-color: #1f00cc;
    text-align: center;
    align-content: center;
    background-color: #1f00cc;
}

.options1 {
    align-items: center;
    justify-content: center;
}

.optionsButton {
    cursor: pointer;


    padding: 0.85rem 2rem;
    color: #ffffff;
    font-weight: 600;
    font-size: 1.25rem;
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.question {
    text-align: center;
    align-content: center;
}

.questionPanel {
    text-align: center;
    /* align-content: center; */
}

.calendarHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dayCell {
    text-align: center;
    width: 60px;
    padding: 6px;
}

.currentDay {
    background-color: #1f00cc;
    color: #ffffff;
}

.listOption {
    /* font-weight: 600; */
    font-size: 1.25rem;
}

@media (min-height: 800px) {

    .listOption {
        font-size: 1.7rem;
    }
}

.centerContent {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    align-content: center;
    /* min-width: 80%; */
}

.infoContent {
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 300px;
}

.centerContent2 {
    margin: auto;
    text-align: center;
    align-content: center;
}

.centerContent3 {
    margin-top: 15px;
    /* margin: auto; */
    text-align: center;
    align-content: center;
    /* border-radius: 1.5rem;
    border-color: black;
    border-style: solid; */
}

.profileButton {
    margin-top: 25px;
    margin-bottom: 25px;


}

.uploadFileButton {
    background: url(/src/assets/banner.png) no-repeat scroll 7px 7px;
    padding-left: 30px;
    width: 100%;
    padding: 10px;
    text-align: center;
}

.questionnaireItemProgress {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 5px;
    left: 5px;
    background: rgba(77, 77, 77, 0.5);
    color: #fff;
    /* border-radius: 1.5rem; */
    /* border-color: darkred; */
    /* border-style: solid; */
    padding-left: 10px;
    padding-right: 10px;
    width: 90px;
    font-size: 0.75rem;
    max-width: 90px;
}

.questionnaireItemSuccess {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 5px;
    left: 2px;
    right: 2px;
    color: #fff;
    /* border-radius: 1.5rem; */
    /* background-color: #00cc66; */
    background: rgba(0, 204, 102, 0.5);
    /* border-color: rgba(0, 204, 102, 0.5); */
    /* border-style: solid; */
    /* padding-left: 0px;
    padding-right: 0px; */
    width: 95%;
    /* max-width: 90px; */
}

.donePanel {
    text-align: center;
    align-content: center;
    width: 97%;
}

.cIcon {
    background: transparent
}

.rightpanel2 {
    /* color: #f5f5f6; */
    float: left;
    /* top: 2px; */

    /* margin: 4px; */
    text-align: center;
    align-content: center;
    width: 100%;
}

.groupItem {

    color: #000;
    font-weight: 600;
    font-size: 1rem;
    /* background-color: rgba(0, 0, 0, 0.2); */

    width: 100%;
    border-radius: 1.5rem;

}

.qrcodes {
    margin-top: 30px;
}

.tour {
    position: absolute;
    top: 0px;
    right: 0px;
}
.errorButton {
    position: absolute;
    top: 6px;
    right: 2px;
    z-index: 99999;
}
.errorInfo {
    position: absolute;
    top: 50px;
    left: 0px;
    z-index: 99999;
}
.help {

    margin: 1em;
}

.helpTitle {

    margin-bottom: 0.5em;
}

.helpDescription {

    margin: 0em;
}

.helpImg {

    margin: 1em;
}

.helpLink {

    margin: 1em;
}

.helpButton {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9999;
    background-color: #fff;
}

.helpButton2 {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9999;
    /* background-color: #fff; */
}


.aaa {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: -100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aaa2 {
    position: relative;
    width: 120px;
    height: 120px;
}

.progressEmotion3 {
    position: relative;
    top: -16px;
    right: 20px;
    padding: 0px;
    margin: 0px;
    width: 30%;
    /* max-width: 220px; */
}

.progressEmotion4 {
    position: relative;
    top: -17px;
    max-width: 220px;
}

.avatar-container {
    display: flex;
    width: 99%;
    justify-content: center;
}

.resultCardImage {
    height: 50px;
    /* height: 5vw; */
    /* width: 100%; */
    /* border-radius: 1.5rem; */
    object-fit: cover;
    margin: 0 auto;
}

.ecolumn1 {
    width: 100px;
}

.ecolumn2 {
    width: 70px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ecolumn3 {
    flex: 1;
}

.apexcharts-tooltip-custom {
    text-align: center;
  }

  .groupIconText {
    max-width: 100px;
    width: 100px;
    word-wrap:break-word; 
    display:inline-block;
  }

  .statSelect {
    margin-top: "-5px !important";
    margin-right: "10px";
    margin-left: "10px";
  }